import React from 'react'
import { IconsProps } from 'msp-integrations'

const ArrowRightIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path d="m51.49 112.3-2.11-2.11L88.14 71.5 49.38 32.81l2.11-2.11 40.87 40.8z" />
    </svg>
  )
}

export default ArrowRightIcon
